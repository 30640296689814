import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "block has-text-right" }
const _hoisted_2 = {
  key: 0,
  class: "panel"
}
const _hoisted_3 = { class: "panel-heading" }
const _hoisted_4 = { class: "panel-block" }
const _hoisted_5 = { class: "panel-block is-justify-content-end" }
const _hoisted_6 = { class: "field is-grouped" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "buttons-top"),
      _createElementVNode("button", {
        class: "button is-dark",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleFilters && _ctx.toggleFilters(...args)))
      }, _toDisplayString(_ctx.$t('general.FILTERS')), 1)
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.panelTitle), 1),
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "panel-body")
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "buttons-bottom")
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}