import { reactive } from 'vue';
import { useRoute } from 'vue-router';

export default function usePagination () {
    const state = reactive({
        currentPage: <number>1,
        lastPage: <number|null>null,
        pageSize: <number>120,
        totalCount: <number|null>null,
    });
    const availablePageSizes = [30, 60, 90, 120];

    const route = useRoute();

    if (route.query.page) {
        state.currentPage = Number(route.query.page);
    }

    if (window.localStorage.getItem('page-size')) {
        state.pageSize = Number(window.localStorage.getItem('page-size'));
    }

    const setCurrentPage = (pageNum: number) => {
        state.currentPage = pageNum;
    };

    const setLastPage = (pageNum: number) => {
        state.lastPage = pageNum;
    };

    const setPageSize = (pageSize: number) => {
        state.pageSize = pageSize;

        window.localStorage.setItem('page-size', String(pageSize));
    };

    const setTotalCount = (count: number) => {
        state.totalCount = count;
    };

    return {
        state,
        availablePageSizes,
        setCurrentPage,
        setLastPage,
        setPageSize,
        setTotalCount,
    };
};
