import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "level" }
const _hoisted_2 = { class: "level-left" }
const _hoisted_3 = { class: "level-item" }
const _hoisted_4 = { class: "subtitle is-5" }
const _hoisted_5 = { class: "level-item" }
const _hoisted_6 = { class: "level-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageSize = _resolveComponent("PageSize")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t('pagination.TOTAL_ITEMS')) + ": ", 1),
          _createElementVNode("strong", null, _toDisplayString(_ctx.pagination.state.totalCount), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_PageSize, { pagination: _ctx.pagination }, null, 8, ["pagination"])
      ]),
      _renderSlot(_ctx.$slots, "left")
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "right")
    ])
  ]))
}