import { reactive, watch } from 'vue';
import i18n from '@/i18n';
import { AxiosResponse } from 'axios';
import { ApiQuery, ApiResourceInterface, IModel } from '@/resources/ApiResource';
import usePagination from '@/composables/use-pagination';
const { t } = i18n.global;

export default function useList(resource: ApiResourceInterface) {
    const pagination = usePagination();
    const state = reactive({
        items: <IModel[]>[],
    });
    const lastQuery = {};

    const removeItem = (item: IModel): void => {
        if (confirm(t('general.CONFIRM_DELETE'))) {
            resource.delete(item).then(() => {
                state.items.splice(state.items.indexOf(item), 1);
            });
        }
    }

    const fetch = (query?: ApiQuery): void => {
        Object.assign(lastQuery, { page: pagination.state.currentPage, pageSize: pagination.state.pageSize }, query);

        resource.query(lastQuery).then((response: AxiosResponse) => {
            state.items = response.data.data;

            const responseMeta = response.data.meta;

            if (responseMeta) {
                pagination.setCurrentPage(responseMeta.current_page);
                pagination.setLastPage(responseMeta.last_page);
                pagination.setTotalCount(responseMeta.total);
            }
        });
    }

    watch(() => {
        return [pagination.state.currentPage, pagination.state.pageSize]
    }, () => {
        fetch();
    })

    return {
        state,
        pagination,
        removeItem,
        fetch,
    };
};
