
import { defineComponent } from 'vue';
import PageSize from '@/components/Pagination/PageSize.vue';

export default defineComponent({
    components: { PageSize },
    props: {
        pagination: {
            type: Object,
            required: true
        }
    },
});
