
import { defineComponent, computed, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { range } from 'lodash';

export default defineComponent({
    props: {
        pagination: {
            type: Object,
            required: true
        }
    },

    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const { currentPage, lastPage } = toRefs(props.pagination.state);

        function setCurrentPage(pageNum: number) {
            const query = { ...route.query, page: pageNum };

            router.replace({ query }).then(() => {
                props.pagination.setCurrentPage(pageNum);
            });
        }

        const pages = computed(() => {
            const LEFT_PAGE = 0;
            const RIGHT_PAGE = 0;
            const pageNeighbours = 4;

            /**
             * totalNumbers: the total page numbers to show on the control
             * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
             */
            const totalNumbers = pageNeighbours * 2 + 3;
            const totalBlocks = totalNumbers + 2;

            if (lastPage.value > totalBlocks) {
                const startPage = Math.max(2, currentPage.value - pageNeighbours);
                const endPage = Math.min(
                    lastPage.value - 1,
                    currentPage.value + pageNeighbours
                );
                let pages = range(startPage, endPage);

                /**
                 * hasLeftSpill: has hidden pages to the left
                 * hasRightSpill: has hidden pages to the right
                 * spillOffset: number of hidden pages either to the left or to the right
                 */
                const hasLeftSpill = startPage > 2;
                const hasRightSpill = lastPage.value - endPage > 1;
                const spillOffset = totalNumbers - (pages.length + 2);

                switch (true) {
                    // handle: (1) < {5 6} [7] {8 9} (10)
                    case hasLeftSpill && !hasRightSpill: {
                        const extraPages = range(
                            startPage - spillOffset,
                            startPage - 1
                        );
                        pages = [LEFT_PAGE, ...extraPages, ...pages];
                        break;
                    }

                    // handle: (1) {2 3} [4] {5 6} > (10)
                    case !hasLeftSpill && hasRightSpill: {
                        const extraPages = range(
                            endPage + 1,
                            endPage + spillOffset
                        );
                        pages = [...pages, ...extraPages, RIGHT_PAGE];
                        break;
                    }

                    // handle: (1) < {4 5} [6] {7 8} > (10)
                    case hasLeftSpill && hasRightSpill:
                    default: {
                        pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                        break;
                    }
                }

                return [1, ...pages, lastPage.value];
            }

            return range(1, (lastPage.value + 1));
        });

        return {
            pages,
            currentPage,
            lastPage,
            setCurrentPage,
        };
    },
});
