
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        pagination: {
            type: Object,
            required: true
        }
    },
});
