
import {defineComponent, ref} from 'vue';

export default defineComponent({
    props: {
        'panelTitle': {
            type: String,
            required: true,
        },
    },

    setup() {
        const isOpen = ref(false);

        function toggleFilters() {
            isOpen.value = !isOpen.value;
        }

        return {
            isOpen,
            toggleFilters,
        }
    }
});
